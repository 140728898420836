<template>
  <div class="my-content">
    <div class="box my-left-display">
      <img @load="getIMGSize" ref="opus" width="100%" height="auto" :src="IMGTest">
    </div>
    <div class="box my-right-display">
      <div class="clearfix">
        <a href="/zh/users/dzeeshah-481870/">
          <img class="the-avatar"
               :src="authorIMG"></a>
        <a href="/zh/users/dzeeshah-481870/">
          <span class="is-size-5">{{ authorName }}</span>
          <span class="" style="margin: 0 2%">/</span>
          <span class="is-size-7">色影师</span>
        </a><br/>
        <div class="the-avatar-buttons">
          <button class="the-avatar-button is-primary button is-small is-rounded">充电</button>
          <button class="the-avatar-button button is-small is-rounded">关注</button>
        </div>

      </div>
      <hr/>
      <div class="lover-buttons is-center">
        <button class="button-lover button is-info is-rounded">
          <i class="iconfont icon-dianzan_huaban"></i>点赞
        </button>
        <button class="button-lover button is-info is-rounded">
          <i class="iconfont icon-shoucang"></i>收藏
        </button>
        <button class="button-lover button is-warning is-rounded">
          <i class="iconfont icon-weibiaoti-"></i>分享
        </button>
      </div>
      <hr/>
      <button class="button is-fullwidth is-rounded is-success">下载</button>
      <hr/>
      <div style="margin:20px 0 10px;padding:15px 20px;background:#f6f5fa;">
        <a href="#" style="color:#636363">图片许可证</a>
        <div style="margin-top:4px;">
          <ul style="font-size: 0.8rem;line-height: 1.5rem">
            <li>可以做商业用途</li>
            <li>不要求署名</li>
          </ul>
        </div>
      </div>
      <hr/>
      <div style="padding:15px 20px;background:#f6f5fa">
        <table>
          <tbody>
          <tr>
            <th style="white-space:nowrap;width: 50%">图片类型</th>
            <td>JPG</td>
          </tr>
          <tr>
            <th>清晰度</th>
            <td>{{ IMGSize.width }}×{{ IMGSize.height }}</td>
          </tr>
          <tr>
            <th>创建</th>
            <td>{{new Date(createDate).toLocaleDateString()}}</td>
          </tr>
          <tr>
            <th>类别</th>
            <td><a href="#">{{imgCategory}}</a></td>
          </tr>
          <tr>
            <th>视图</th>
            <td>109635</td>
          </tr>
          <tr>
            <th>下载量</th>
            <td>60798</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import {backToSnowKey} from "../untils/snowKey";
import {get} from "../untils/request";

export default {
  name: "getIMGDetail",
  props: ["imgID"],
  data() {
    return {
      IMG_ID: "",
      IMGSize: {
        width: "",
        height: ""
      },
      IMGTest: require("@/assets/loadingIMGs/Loading_IMG.jpg"),
      authorIMG: require("@/assets/avatar/DefautAvatar.jpg"),
      authorName: "Loading",
      imgCategory: "loading",
      createDate:"loading"
    }
  },
  methods: {
    getIMGSize() {
      this.IMGSize.width = this.$refs.opus.naturalWidth;
      this.IMGSize.height = this.$refs.opus.naturalHeight;
    }
  },
  mounted() {
    this.IMG_ID = backToSnowKey(this.imgID)
    if (this.IMG_ID != null) {
      get("/imageHost/getInfoIMG", {
        imgID: this.IMG_ID
      })
        .then(e => {
          this.$store.state.metaModule.metaInfo.title=e.data.imageName
          this.IMGTest = this.$store.state.apiHost + e.data.imageHostPath
          this.imgCategory=e.data.imageType
          this.createDate=e.data.createDate
          this.authorName = e.data.uploadUser
        })
    }
    this.$nextTick(() => {

      }
    )

  }
}
</script>

<style scoped>
@import '//at.alicdn.com/t/font_2867504_hdww7ni4efm.css';
@media screen and (min-width: 1050px) {
  .my-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (min-width: 1050px) {
  .my-left-display {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 75%;
  }
}

@media screen and (min-width: 1050px) {
  .my-right-display {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 25%;
  }
}

.the-avatar {
  float: left;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  margin: 0 12px 0 0;
}

.the-avatar-button {
  font-size: 0.65rem;
}

.lover-buttons {
  border-left: 0;
  border-right: 0;
}

.button-lover {
  font-size: 0.75rem;
  margin: 2px;
  width: 28%;
  max-width: 100px;
}

.the-avatar-button {
  margin-right: 5px;
}

hr {
  margin-bottom: 1.5rem;
}

i {
  margin-right: 0.2rem;
}
</style>
